import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "twin.macro"
import Imgix from "react-imgix"

import ContentGrid from "../components/contentGrid"
import unified from 'unified'
import parse from 'rehype-parse'
import rehypeReact from 'rehype-react'
import ArchiveButton from "../components/archiveButton"

const renderAst = new rehypeReact({
  createElement: React.createElement,
  Fragment: React.Fragment,
  components: {
    a: props => {
      return (
        <a href={props.href} title={props.children} target="_blank" rel="noopener noreferrer" tw="underline hover:no-underline">
          {props.children}
        </a>
      )
    },
    img: props => {
      return (
        <Imgix
          src={props.src}
          sizes="(max-width: 880px ) 100vw, 880px"
          htmlAttributes={{
            alt: props.alt,
          }}
        />
      )
    }
  },
}).Compiler

const NewsPost = ({ data, pageContext, location }) => {
  const htmlAst = unified()
    .use(parse, {fragment: true})
    .parse(data.microcmsNews.article)

  return(
    <Layout workPage={true}>
      <Seo 
        title={data.microcmsNews.title} 
        description={data.microcmsNews.title}
        workPage={true}
        pagePath={location.pathname}
        thumbPath={data.microcmsNews.main_img.url}
      />
        <ContentGrid>
        <article className="work_content mt-40">
          <time tw="font-en text-gold">posted : {data.microcmsNews.publishedAt}</time>
          <h2 tw="text-gold text-lg">{data.microcmsNews.title}</h2>

          {
            data.microcmsNews.event_day !=null && (
              <div tw="mt-10 py-6 border-t border-b border-gold">
                  <ul tw="mt-2">
                    {
                      data.microcmsNews.event_day && <li tw="mt-2">{data.microcmsNews.event_day}</li>
                    }
                    {
                      data.microcmsNews.event_spot && <li tw="mt-2">{data.microcmsNews.event_spot}</li>
                    }
                    {
                      data.microcmsNews.event_address && <li tw="mt-2">{data.microcmsNews.event_address}</li>
                    }
                    {
                      data.microcmsNews.reference_url && <li tw="mt-2"><a href={data.microcmsNews.reference_url} target="_blank" rel="noopener noreferrer" tw="underline hover:no-underline">{data.microcmsNews.reference_url}</a></li>
                    }
                    
                  </ul>
              </div>
            )
          }
          <div tw="mt-10">{renderAst(htmlAst)}</div>
          <div tw="mt-10">
              <Imgix
                src={data.microcmsNews.main_img.url}
                sizes="(max-width: 800px) 100vw, 800px"
                htmlAttributes={{
                  alt: "",
                }}
                tw="m-0 w-full"
              />
          </div>
          <ul tw="grid grid-cols-2 gap-x-6 mt-20">
            {
              pageContext.next && (
                <li tw="col-start-1 col-end-2">
                  <Link 
                    to={`/news/${pageContext.next.newsId}/`} 
                    rel="prev" 
                    title={pageContext.next.title}
                    tw="
                      h-40
                      overflow-hidden
                      block
                      relative
                      rounded
                    "
                    className="group"
                  >
                    <Imgix
                      src={pageContext.next.main_img.url}
                      sizes="(max-width: 800px) 100vw, 400px"
                      htmlAttributes={{
                        alt: `${pageContext.next.title}`,
                      }}
                      tw="w-full h-full object-cover"
                    />
                    <span
                      tw="
                        transition-colors
                        absolute 
                        top-0 
                        left-0 
                        w-full 
                        h-full 
                        bg-black
                        bg-opacity-50
                        group-hover:bg-opacity-0
                      "
                    ></span>
                    <span tw="
                      flex 
                      justify-center 
                      items-center 
                      absolute 
                      top-0 
                      left-0 
                      w-full 
                      h-full 
                      p-4
                      text-white
                      text-center
                      text-sm
                    ">{pageContext.next.title}</span>
                  </Link>
                </li>
              )
            }
            {
              pageContext.previous && (
                <li tw="col-start-2 col-end-3">
                  <Link 
                    to={`/news/${pageContext.previous.newsId}/`} 
                    rel="prev" 
                    title={pageContext.previous.title}
                    tw="
                      h-40
                      overflow-hidden
                      block
                      relative
                      rounded
                    "
                    className="group"
                  >
                    <Imgix
                      src={pageContext.previous.main_img.url}
                      sizes="(max-width: 800px) 100vw, 400px"
                      htmlAttributes={{
                        alt: `${pageContext.previous.title}`,
                      }}
                      tw="w-full h-full object-cover"
                    />
                    <span
                      tw="
                        transition-colors
                        absolute 
                        top-0 
                        left-0 
                        w-full 
                        h-full 
                        bg-black
                        bg-opacity-50
                        group-hover:bg-opacity-0
                      "
                    ></span>
                    <span tw="
                      flex 
                      justify-center 
                      items-center 
                      absolute 
                      top-0 
                      left-0 
                      w-full 
                      h-full 
                      p-4
                      text-white
                      text-center
                      text-sm
                    ">{pageContext.previous.title}</span>
                  </Link>
                </li>
              )
            }
          </ul>
          <div tw="mt-6">
          <ArchiveButton/>
          </div>
          
          <footer tw="py-10 pb-20">
            <small tw="font-en text-gold text-lg tracking-wider">© Yumiko Kinjo</small>
          </footer>
        </article>
        
      </ContentGrid>
    </Layout>
  )
}

export default NewsPost

export const query = graphql`
  query($id: String!) {
    microcmsNews(id: { eq: $id }) { 
      title
      publishedAt(formatString: "YYYY.MM.DD")
      main_img {
        url
      }
      event_day
      event_spot
      event_address
      reference_url
      article
    }
  }
`
