import React from 'react'
import { Link } from "gatsby"
import "twin.macro"

const ArchiveButton = ({ id, title, featuredImg, index }) => {
  return(
    <Link 
      to={`/news/`}
      title="NEWS ARCHIVE"
      tw="block w-full bg-gold bg-opacity-0 hover:bg-opacity-100 border transition border-gold mt-2 py-2 px-2 rounded-md font-en"
      className="group link_arrow-wrap"
    >
      <span className="link_arrow" tw="transition-all tracking-wider block flex  items-center text-gold group-hover:text-white">ARCHIVE</span>
    </Link>
  )
}

export default ArchiveButton